@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?fc0f8v');
  src:  url('../fonts/icomoon.eot?fc0f8v#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?fc0f8v') format('truetype'),
    url('../fonts/icomoon.woff?fc0f8v') format('woff'),
    url('../fonts/icomoon.svg?fc0f8v#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye:before {
  content: "\e91d";
}
.icon-makeup .path1:before {
  content: "\e90e";
  color: rgb(255, 255, 255);
}
.icon-makeup .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(186, 130, 170);
}
.icon-makeup .path3:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-makeup .path4:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-makeup .path5:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-makeup .path6:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(186, 130, 170);
}
.icon-makeup .path7:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(186, 130, 170);
}
.icon-makeup .path8:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(186, 130, 170);
}
.icon-makeup .path9:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(186, 130, 170);
}
.icon-makeup .path10:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(127, 57, 96);
}
.icon-makeup .path11:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 166, 187);
}
.icon-makeup .path12:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(127, 57, 96);
}
.icon-makeup .path13:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(186, 130, 170);
}
.icon-makeup .path14:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(127, 57, 96);
}
.icon-sterile-gloves:before {
  content: "\e91c";
}
.icon-alarm-clock:before {
  content: "\e900";
}
.icon-calendar-with-a-clock-time-tools:before {
  content: "\e901";
}
.icon-conversation:before {
  content: "\e902";
}
.icon-customer-help-clerk:before {
  content: "\e903";
}
.icon-event-date-and-time-symbol:before {
  content: "\e904";
}
.icon-face-of-a-woman-outline:before {
  content: "\e905";
}
.icon-female-graduate-student:before {
  content: "\e906";
}
.icon-film-roll:before {
  content: "\e907";
}
.icon-hair-scissors-and-comb:before {
  content: "\e908";
  color: #7e293d;
}
.icon-lipstick:before {
  content: "\e909";
}
.icon-mirror:before {
  content: "\e90a";
}
.icon-mother-walking-with-her-son-on-a-stroller:before {
  content: "\e90b";
}
.icon-paint-class:before {
  content: "\e90c";
}
.icon-star:before {
  content: "\e90d";
}
